body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 适用于网页的整个滚动条 */
::-webkit-scrollbar {
  width: 4px; /* 设置滚动条的宽度（垂直滚动条） */
  height: 4px; /* 设置滚动条的高度（水平滚动条） */
}

/* 滚动条的轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的背景色 */
  border-radius: 10px; /* 设置圆角 */
}

/* 滚动条的滑块（滚动条可以被拖动的位置） */
::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块的颜色 */
  border-radius: 10px; /* 设置圆角 */
}

/* 滚动条滑块在悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 在悬停时改变滑块的颜色 */
}

/* 移除滚动条上的箭头 */
::-webkit-scrollbar-button {
  display: none; /* 隐藏滚动条上的箭头 */
}

/*点击动画样式-流光*/
.glow {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 20px #4682B4;
  opacity: 0.8;
  animation: glow-effect 1s ease-out;
  pointer-events: none; /* 将 pointer-events 属性设置为 none */
}

@keyframes glow-effect {
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
