body{
  background: url("https://img.picknewtab.com/wallpaperTab/file/background.svg");
  background-size: cover;
}

.navbar{
  height:80px;
  margin-bottom: 5px;
  box-shadow: 1px 1px 15px 3px #0002;
  display: flex;
  align-items: center; /* 垂直居中子元素 */
  padding-left: 15px;
}
@media  (max-width: 800px) {
  .navbar{
    height: 60px;
  }
}

.logoIcon{
  width: 20vw;
  height: 64px;
}

.content{
  display: flex;           /* 启用flexbox */
  flex-direction: row;     /* 子元素水平排列 */
  max-width: 1000px;
  margin: auto auto;
}
.loading{
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wallpaper-sector {
  width: 100%;
}
.wallpaper-list {
  display: flex;
  flex-wrap: wrap;
}
.page-number-input{
  margin: 5px;
}
.page-number-input input {
  width: 50px;
  text-align: center;
}
.page-number-input span{
  margin-left: 5px;
}

/* 移除遮罩 */
.ant-image-mask {
  display: none !important;
}

.wallpaper-preview{
  cursor: pointer;
  position: relative;
}

.image-preview{
  transition: box-shadow 0.5s ease;
}
.image-preview:hover{
  box-shadow: 1px 1px 5px 2px dimgray;
}


.wallpaper-preview > .wallpaperButtonDiv > .wallpaperOpButton{
  opacity: 0;
  transition: opacity 0.3s, color 0.3s; /* 将过渡效果应用于透明度和颜色 */
}

.wallpaper-preview:hover > .wallpaperButtonDiv > .wallpaperOpButton{
  opacity:1;
}

.wallpaperOpButton{
  color: #fff;
  padding: 5px 10px;
  margin: 5px;
}

.wallpaperOpButton:hover{
    color: deepskyblue;
}
.wallpaperOpButtonAlert:hover{
  color: orangered !important;
}

@media  (max-width: 600px) {
  .wallpaperOpButton{
    font-size: 8px;
    padding: 2px 4px;
    margin: 2px;
  }
}

.wallpaperNameBanner{
  opacity: 0;
  transition: opacity 0.3s;
}
.wallpaper-preview:hover > .wallpaperNameBanner{
  opacity:1;
}
.wallpaperStatusBanner{
  opacity: 1;
  transition: opacity 0.3s;
}
.wallpaper-preview:hover > .wallpaperStatusBanner{
  opacity: 0;
}

.wallpaperDetailDisplay{
    margin-left: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media  (max-width: 1200px) {
  .wallpaperDetailDisplay{
    margin: 10px;
  }
}
.wallpaperDetailSection{
  margin: 50px;
}
@media  (max-width: 1200px) {
  .wallpaperDetailSection{
    margin: 10px;
  }
}

.wallpaperDetailButton {
  margin: 10px;
}
@media  (max-width: 1000px) {
  .wallpaperDetailButton{
    margin: 2px;
  }
}
.wallpaperDetail{
  font-size: 20px;
}
@media  (max-width: 1000px) {
  .wallpaperDetail{
    font-size: 14px;
  }
}

/*精选壁纸区域介绍*/
.bannerTitle{
  font-size: 24px;
}

@media  (max-width: 1000px) {
  .bannerTitle{
    font-size: 12px;
  }
}

.bannerDescription{
  display: block;
}

@media  (max-width: 1000px) {
  .bannerDescription{
    display: none;
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保在其他内容之上 */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* 确保在遮罩之上 */
}
